import { Injectable } from '@angular/core';
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  // transforme une chaine pour pouvoir la passer dans une URL
  transformStringToUrlFormat(input: string): string {
    return input
      .toLowerCase()               // Convertit la chaîne en minuscule
      .trim()                      // Enlève les espaces au début et à la fin
      .replace(/\s+/g, '-')        // Remplace tous les espaces par des tirets
      .replace(/[^\w\-]+/g, '')    // Supprime les caractères non-alphanumériques
      .replace(/\-\-+/g, '-');     // Remplace plusieurs tirets consécutifs par un seul
  }

  // Fonction pour mélanger un tableau
  shuffleArray(array: any[]): any[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap
    }
    return array;
  }

  formatStringPayment(value: string, maxLength: number): string {
    // 1. Convertir les caractères en majuscules et enlever les accents
    value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();

    // 2. Supprimer les caractères non alphanumériques (hors A-Z, 0-9 et espaces)
    value = value.replace(/[^A-Z0-9\s]/g, '');

    // 3. Remplacer les espaces multiples par un seul espace
    value = value.replace(/\s+/g, ' ');

    // 4. Tronquer la chaîne à la longueur maximale spécifiée
    value = value.substring(0, maxLength);

    // 5. Supprimer les espaces en début et fin de chaîne
    return value.trim();
  }

  replaceSimpleQuotes(str: string): string {
    return str.replace(/'/g, "&#039;");
  }

  async generateHmacSha512(message: string, keyHex: string): Promise<string> {
    // Convertir la clé hexadécimale en bytes
    const keyBytes = new Uint8Array(keyHex.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));

    // Importer la clé HMAC dans Web Crypto
    const key = await crypto.subtle.importKey(
      'raw',
      keyBytes,
      { name: 'HMAC', hash: { name: 'SHA-512' } },
      false,
      ['sign']
    );

    // Encoder le message en UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // Calculer le HMAC
    const signature = await crypto.subtle.sign('HMAC', key, msgBuffer);

    // Convertir le résultat en hex et en uppercase
    const hmacHex = Array.from(new Uint8Array(signature))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('')
      .toUpperCase();

    return hmacHex;
  }

  getCurrentDateISO(): string {
    const date = new Date();

    // Convertir la date en ISO 8601
    const isoString = date.toISOString();

    // Extraire la date et l'heure
    const datePart = isoString.split('.')[0];

    // Calculer le décalage horaire en minutes
    const timezoneOffset = -date.getTimezoneOffset();
    const sign = timezoneOffset >= 0 ? '+' : '-';
    const pad = (n: number) => String(n).padStart(2, '0');

    // Convertir le décalage en heures et minutes
    const hoursOffset = pad(Math.floor(Math.abs(timezoneOffset) / 60));
    const minutesOffset = pad(Math.abs(timezoneOffset) % 60);

    // Combiner la date avec le décalage horaire au format ISO 8601
    return `${datePart}${sign}${hoursOffset}:${minutesOffset}`;
  }

  getPhoneIndicator(libellePays: string): string {
    return (libellePays.toLowerCase() == 'france' ? '+33' : '+34')
  }

  differenceEnHeures(dateDebut: Date, dateFin: Date): number {
    const differenceEnMilliseconds = Math.abs(dateFin.getTime() - dateDebut.getTime());
    const differenceEnHeures = differenceEnMilliseconds / (1000 * 60 * 60); // Conversion en heures
    return differenceEnHeures;
  }

  cleanString(str: string): string {
    if(str) {
        str = str.replace(/&nbsp;/g, " ");
        str = str.replace(/<br>/g, " ");
        str = str.replace(/<br\/>/g, " ");

        // Remplacer les espaces multiples par un seul espace
        str = str.replace(/\s+/g, " ");

        // Supprimer les espaces en début et fin de chaîne
        str = str.trim();
    }
    return str;
  }

  getTheList(form: FormGroup, list: any): void {
    this.simulateEnterKey();
    const listValue = list.join('|');
    form.patchValue({ param_list: listValue } );
  }
  simulateEnterKey() {
    const event = new KeyboardEvent('keydown', {
      key: 'Enter',
      code: 'Enter',
      bubbles: true,
      cancelable: true
    });
    const chipsInput = document.querySelector('p-chips input') as HTMLInputElement;
    if (chipsInput) {
      chipsInput.dispatchEvent(event);
    }
  }
  updateDLCoptions(value: any, mode: any, byDefault: any, longueur: any, form: FormGroup) {
    let selectedValue: string | null = null;
    let selectedValuedefault: string | null = null;
    let selectedValuelongueur: number | null = null;
    if (value == 0) {
      mode = [
        {label: 'Calendrier',value: 'calendar'}
      ];
      selectedValue = 'calendar';
      byDefault = [
        {value: 'date(\'d/m/Y\')'}
      ];
      selectedValuedefault = 'date(\'d/m/Y\')';
      longueur = [
        {label: 'Longueur auto', value: 0}
      ];
      selectedValuelongueur = 0;
    }
    form.patchValue({ param_mode: selectedValue } );
    form.patchValue({ param_default: selectedValuedefault } );
    form.patchValue({ param_length: selectedValuelongueur } );
  }
  updateModeOptions(value: string | number, mode: any, byDefault: any, longueur: any,
                    ouiNon: any, modeText: any, longueurText: any, form: FormGroup): void {
    let selectedValue: string;
    let selectedValuedefault: string | null = null;
    let selectedValuelongueur: number;
    let selectedValueOuiNon: number | null = null;
    switch (value) {
      case 'hour':
        mode = [
          {label: 'Horloge', value: 'clock'},
        ];
        byDefault = [
          { value: 'date(\'H:i\')'}
        ];
        longueur = [
          {label: 'Longueur auto', value: 0}
        ];
        ouiNon = [
          {label: 'Oui', value: 1},
          {label: 'Non', value: 0},
        ];
        selectedValue = 'clock';
        selectedValuedefault = 'date(\'H:i\')';
        selectedValuelongueur = 0;
        selectedValueOuiNon = 0;
        break;
      case 'date':
        modeText = [
          {label: 'Calendrier', value: 'calendar'},
        ];
        byDefault = [
          { value: 'date(\'d/m/Y\')'}
        ];
        longueurText = [
          {label: 'Longueur auto', value: 0}
        ];
        ouiNon = [
          {label: 'Oui', value: 1},
          {label: 'Non', value: 0},
        ];
        selectedValue = 'clock';
        selectedValuedefault = 'date(\'d/m/Y\')';
        selectedValuelongueur = 0;
        selectedValueOuiNon = 0;
        break;
      case 'separator':
      case 'comment':
        longueurText = [
          {label: 'Longueur auto', value: 100}
        ];
        selectedValue = '';
        selectedValuedefault = '';
        selectedValuelongueur = 100;
        break;
      case 'varchar':
        modeText = [
          {label: 'Champ libre', value: ''},
          {label: 'Liste', value: 'list'},
          {label: 'Table', value: 'table'},
        ];
        longueurText = [
          {label: '5', value: 5},
          {label: '10', value: 10},
          {label: '30', value: 30},
          {label: '50', value: 50}
        ];
        ouiNon = [
          {label: 'Oui', value: 1},
          {label: 'Non', value: 0},
        ];
        selectedValue = '';
        selectedValueOuiNon = 0;
        selectedValuelongueur = 5;
        break;
      case 'int':
        modeText = [
          {label: 'Variateur', value: 'touchspin'},
        ];
        longueurText = [
          {label: '10', value: 10}
        ];
        ouiNon = [
          {label: 'Oui', value: 1},
          {label: 'Non', value: 0},
        ];
        selectedValue = 'touchspin';
        selectedValuedefault = '';
        selectedValuelongueur = 10;
        selectedValueOuiNon = 0;
        break;
      case 'float':
        modeText = [
          {label: 'Variateur', value: 'touchspin'},
        ];
        longueurText = [
          {label: 'Longueur auto', value: 10.2}
        ];
        ouiNon = [
          {label: 'Oui', value: 1},
          {label: 'Non', value: 0},
        ];
        selectedValue = 'touchspin';
        selectedValuedefault = '';
        selectedValuelongueur = 10.2;
        selectedValueOuiNon = 0;
        break;
      default:
        modeText = [
          {label: 'Liste', value: 'list'},
          {label: 'Table', value: 'table'},
          {label: 'Variateur', value: 'touchspin'}
        ];
        byDefault = [
          {label: '', value: null}
        ];
        longueurText = [
          {label: '5', value: 5},
          {label: '10', value: 10},
          {label: '30', value: 30},
          {label: '50', value: 50}
        ];
        selectedValue = '';
        selectedValuedefault = '';
        selectedValuelongueur = 5;
        break;
    }
    form.patchValue({ param_mode: selectedValue } );
    form.patchValue({ param_default: selectedValuedefault } );
    form.patchValue({ param_length: selectedValuelongueur } );
    form.patchValue({ param_readOnly: selectedValueOuiNon } );

    form.patchValue({ param_dlc: -1 } );
  }
  updateOptions(value: any, mode: any, byDefault: any, longueur: any,
                ouiNon: any, modeText: any, longueurText: any, form: FormGroup) {
    this.updateModeOptions(value, mode, byDefault, longueur,
      ouiNon, modeText, longueurText, form);
    if (value === 'calendar') {
      this.updateDLCoptions(value, mode, byDefault, longueur, form);
    }
  }

  capitalize(value: string): string {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
